<template>
  <div class="input">
    <LoadingComponent v-if="isMounting || isExecuting" />
    <!-- underlayer-main -->
    <main class="underlayer-main">
      <h1>Portas新規会員登録 お客様情報入力</h1>
    </main>
    <!-- /underlayer-main -->

    <!-- contents -->
    <div class="contents">
      <!-- breadcrumb -->
      <ul class="breadcrumb">
        <li v-if="!isGameWith"><router-link to="/">トップページ</router-link></li>
        <li>新規会員登録</li>
        <li>お客様情報入力</li>
      </ul>
      <!-- /breadcrumb -->

      <!-- application-flow -->
      <ul class="application-flow grid pc-grid5 sp-grid5 gap10">
        <li>お申し込み受付</li>
        <li class="stay">お客様情報入力</li>
        <li>Portas会員限定 推奨サービスのご案内</li>
        <li>Portas新規会員登録・サービスお申し込み最終確認</li>
        <li>完了</li>
      </ul>
      <!-- /application-flow -->

      <!-- blc:新規利用者登録 -->
      <div class="blc">
        <div>
          <!-- エラーメッセージはエラーメッセージコンポーネント側で表示する -->
          <error-messages-component v-if="errorMessages.length > 0" :errorMessages="errorMessages" :errorMessageTitle="errorMessageTitle" />
        </div>
        <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="お客様情報入力" />お客様情報入力</h2>
        <table class="table-type2">
          <tr>
            <th class="va-middle">お名前（全角）<span class="req">必須</span></th>
            <td>
              <input type="text" class="text middle" placeholder="姓" maxlength="28" :value="getItem('firstName')" @input="updateItem($event, 'firstName')" />&emsp;<input
                type="text"
                class="text middle sp-mt10"
                placeholder="名"
                maxlength="28"
                :value="getItem('lastName')"
                @input="updateItem($event, 'lastName')"
              />
            </td>
          </tr>
          <tr>
            <th class="va-middle">フリガナ（全角）<span class="req">必須</span></th>
            <td>
              <input type="text" class="text middle" placeholder="セイ" maxlength="28" :value="getItem('firstNameKana')" @input="updateItem($event, 'firstNameKana')" />&emsp;<input
                type="text"
                class="text middle sp-mt10"
                placeholder="メイ"
                maxlength="28"
                :value="getItem('lastNameKana')"
                @input="updateItem($event, 'lastNameKana')"
              />
            </td>
          </tr>
          <tr>
            <th class="va-middle">メールアドレス</th>
            <td style="vertical-align: middle; overflow-x: auto; max-width: 920px">
              <span class="font16px" style="max-width: 900px">{{ form.mailAddress }}</span>
            </td>
          </tr>
          <tr>
            <th>生年月日（半角数字）<span class="req">必須</span></th>
            <td>
              <input type="text" class="text short" placeholder="西暦" maxlength="4" :value="getItem('birthYear')" @input="updateItem($event, 'birthYear')" />
              年&emsp;
              <select name="month" class="form-select" :value="getItem('birthMonth')" @input="updateItem($event, 'birthMonth')">
                <option v-for="n in 12" :key="n" v-bind:value="n">{{ n }}</option>
              </select>
              月&emsp;
              <select name="year" class="form-select" :value="getItem('birthDay')" @input="updateItem($event, 'birthDay')">
                <option v-for="n in 31" :key="n" v-bind:value="n">{{ n }}</option>
              </select>
              日
              <p class="att red mt10">※生年月日は一度登録すると後から変更はできません。</p>
            </td>
          </tr>
        </table>

        <!--GW光の場合お住まい登録エリアは非表示-->
        <template v-if="!isGameWith">
          <!-- 会員ステータス.遷移元サービス情報から所得した物件が存在するとき -->
          <template v-if="existsInParameterDecisionTable && propertyFromTransitionSourceServiceId && propertyFromTransitionSourceServiceId.id">
            <table class="table-type2">
              <tbody>
                <tr>
                  <th>お住まいの建物</th>
                  <td>
                    <p class="font18px mb10">
                      <b>{{ propertyFromTransitionSourceServiceId.dispApartmentName }}</b>
                    </p>
                    <p>〒{{ propertyFromTransitionSourceServiceId.zipcode }} {{ propertyFromTransitionSourceServiceId.address1 }}</p>
                    <p class="att red mt10">※お住まいの建物が棟で分かれている場合など、一部実際のご住所と異なる場合がございます。</p>
                    <p class="att red mt10">※お住まいの建物は登録を変更することができません。</p>
                  </td>
                </tr>
              </tbody>
            </table>

            <div class="blc">
              <p class="form-btn-txt">ご入力内容、ご選択された建物にお間違いがなければ「確認」ボタンを押してください。</p>
              <div class="btn-area">
                <button class="btn btn01 bs" v-on:click="onNextWithpropertyFromTransitionSourceServiceId()">確認</button>
              </div>
            </div>
          </template>

          <!-- 会員ステータス.遷移元サービス情報から所得した物件が存在しないとき -->
          <template v-else>
            <div>
              <ResidenceRegistrationController ref="residenceRegistrationController" :handle-is-after-register="changeIsAfterRegister" @clickUpdateRadios="updateRadios" />
            </div>
            <!-- Portasサービス契約住所 -->
            <table class="table-type2">
              <tbody>
                <tr class="billing-address-tr">
                  <th class="va-middle">Portasサービス契約住所</th>
                  <td class="billing-address-td"></td>
                </tr>
                <tr class="billing-address-tr">
                  <th class="va-middle">郵便番号（半角数字）</th>
                  <td class="billing-address-td">
                    <input type="text" class="text short" placeholder="000" maxlength="3" v-model="form.zipcode1" />
                    <span> - </span>
                    <input type="text" class="text short" placeholder="0000" maxlength="4" v-model="form.zipcode2" />
                    <button @click="searchAddressByZipcode()" class="apartment-search-button">住所検索</button>
                  </td>
                </tr>
                <tr class="billing-address-tr">
                  <th class="va-middle">住所（全角）</th>
                  <td class="billing-address-td">
                    <input type="text" class="text large" placeholder="都道府県　市区町村　番地" v-model="form.address" />
                  </td>
                </tr>
                <tr class="billing-address-tr">
                  <th class="va-middle">建物名（全角）</th>
                  <td class="billing-address-td">
                    <input type="text" class="text large" placeholder="マンション名・ビル名" v-model="form.apartmentBlockName" />
                  </td>
                </tr>
                <tr class="billing-address-tr">
                  <th class="va-middle">棟・部屋番号（全角）</th>
                  <td class="billing-address-td">
                    <input type="text" class="text middle" placeholder="棟・部屋番号" v-model="form.roomNumber" />
                  </td>
                </tr>
                <tr class="billing-address-tr"></tr>
                <th></th>
                <td class="billing-address-td">
                  <div class="red">
                    <p class="att">
                      ※Portasサービスお申し込みをご希望の場合、郵便番号・住所は必須入力項目です。Portasサービスをお申し込みされない場合は、ご契約住所の入力は任意です。
                    </p>
                    <p class="att">※登録した住所は、Portasマイページでの修正ができません。修正をご希望の場合は、お手数ですが、Portasお問い合わせフォームからご連絡ください。</p>
                    <p class="att">
                      ※ご契約サービスに関するご案内やご請求時の不備等においてメールでご連絡が行えない場合、書面を発送する場合がございますのであらかじめご了承ください。
                    </p>
                  </div>
                </td>
              </tbody>
            </table>

            <template v-if="isAfterRegister">
              <div class="blc">
                <p class="form-btn-txt">入力内容をご確認の上、よろしければ「次へ」ボタンを押してください。</p>
                <div class="btn-area">
                  <button class="btn btn01 bs" v-on:click="onNextWithoutProperty()">次へ</button>
                </div>
              </div>
            </template>

            <template v-else-if="!isAfterRegister">
              <div class="blc">
                <p class="form-btn-txt">ご入力内容、ご選択された建物にお間違いがなければ「次へ」ボタンを押してください。</p>
                <div class="btn-area">
                  <button class="btn btn01 bs" v-on:click="onNext()">次へ</button>
                </div>
              </div>
            </template>

            <!-- 会員ステータス.遷移元サービス情報から所得した物件が存在しないとき -->
            <template v-else>
              <div>
                <ResidenceRegistrationController ref="residenceRegistrationController" :handle-is-after-register="changeIsAfterRegister" @clickUpdateRadios="updateRadios" />
              </div>

              <template v-if="isAfterRegister">
                <div class="blc">
                  <p class="form-btn-txt">入力内容をご確認の上、よろしければ「確認」ボタンを押してください。</p>
                  <div class="btn-area">
                    <button class="btn btn01 bs" v-on:click="onNextWithoutProperty()">確認</button>
                  </div>
                </div>
              </template>

              <template v-else-if="!isAfterRegister">
                <div class="blc">
                  <p class="form-btn-txt">ご入力内容、ご選択された建物にお間違いがなければ「確認」ボタンを押してください。</p>
                  <div class="btn-area">
                    <button class="btn btn01 bs" v-on:click="onNext()">確認</button>
                  </div>
                </div>
              </template>
            </template>
          </template>
        </template>
        <!--GW光のとき-->
        <template v-else>
          <!-- Portasサービス請求先住所 -->
          <table class="table-type2 address-table">
            <tbody>
              <tr class="billing-address-tr">
                <th class="va-middle">Portasサービス請求先住所</th>
                <td class="billing-address-td"></td>
              </tr>
              <tr class="billing-address-tr">
                <th class="va-middle">郵便番号（半角数字）</th>
                <td class="billing-address-td">
                  <input type="text" class="text short" placeholder="000" maxlength="3" v-model="form.zipcode1" />
                  <span> - </span>
                  <input type="text" class="text short" placeholder="0000" maxlength="4" v-model="form.zipcode2" />
                  <button @click="searchAddressByZipcode()" class="apartment-search-button">住所検索</button>
                </td>
              </tr>
              <tr class="billing-address-tr">
                <th class="va-middle">住所（全角）</th>
                <td class="billing-address-td">
                  <input type="text" class="text large" placeholder="都道府県　市区町村　番地" v-model="form.address" />
                </td>
              </tr>
              <tr class="billing-address-tr">
                <th class="va-middle">建物名（全角）</th>
                <td class="billing-address-td">
                  <input type="text" class="text large" placeholder="マンション名・ビル名" v-model="form.apartmentBlockName" />
                </td>
              </tr>
              <tr class="billing-address-tr">
                <th class="va-middle">棟・部屋番号（全角）</th>
                <td class="billing-address-td">
                  <input type="text" class="text middle" placeholder="棟・部屋番号" v-model="form.roomNumber" />
                </td>
              </tr>
              <tr class="billing-address-tr"></tr>
              <th></th>
              <td class="billing-address-td">
                <p class="red">※Portasサービスお申し込みをご希望の場合、郵便番号・住所は必須入力項目です。<br />※Portasサービス請求先住所は一度登録すると後から変更できません。</p>
              </td>
            </tbody>
          </table>
          <div class="blc">
            <p class="form-btn-txt">入力内容をご確認の上、よろしければ「確認」ボタンを押してください。</p>
            <div class="btn-area">
              <button class="btn bs" :class="[isDisabled ? 'btn04' : 'btn01']" v-on:click="onNextWithoutPropertyByGW()" :disabled="isDisabled">確認</button>
            </div>
          </div>
        </template>
      </div>
    </div>
    <!-- /contents -->
  </div>
</template>

<style lang="scss" scoped>
.req {
  background-color: #ef3333;
  font-size: 12px;
  font-weight: normal;
  color: #ffffff;
  float: right;
  line-height: 20px;
  padding: 0 5px;
  border-radius: 3px;
}

select[name='month'] {
  background-color: #ffffff;
}

select[name='year'] {
  background-color: #ffffff;
}

.apartment-search-button {
  appearance: none;
  border: 0;
  border-radius: 2px;
  font-weight: bold;
  color: #000000;
  background-color: #ffffff;
  border: 2px solid #cf1225;
  padding: 10px 25px;
  line-height: 14px;
  font-size: 14px;
  margin: 0 5px;
  top: 2px;
  box-sizing: border-box;
  position: relative;
}

.notice {
  color: #cf1225;
  text-decoration: underline;
}

.billing-address-td {
  vertical-align: middle;
  padding: 0px;
}

.billing-address-tr {
  border-bottom: none;
}

.notice-border {
  border-bottom: 1px solid #d3d3d3;
}

table.address-table {
  margin-top: 0px;
  margin-bottom: 0px;
  border-top: none;
}
</style>

<script lang="ts">
import useVuelidate from '@vuelidate/core';
import { maxLength, minLength, numeric, required } from '@vuelidate/validators';
import { defineComponent } from 'vue';

import { InputPropertyForm } from '../../../store/platform/platform-edit-store';

import { SpfApiExternalGamewithAccessor } from '@/infra/accessor/spf/gamewith/spf-api-external-gamewith-accessor';
import { ApiFrontError } from '@/shared/classes/error/api-front-error';
import { ExternalSearchPropertiesPropertyInfoDto } from '@/shared/classes/external-api/search-properties/external-search-properties-property-info-dto';
import { PlatformEntryInputForm } from '@/shared/classes/platform/entry-input-form';
import { Address } from '@/shared/classes/spf-api/address';
import { MemberStatus } from '@/shared/classes/spf-api/member-status';
import { Property } from '@/shared/classes/spf-api/property';
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import ResidenceRegistrationController from '@/shared/components/platform/residence-registration-controller.vue';
import { FRONT_ERROR_INFO_API_FRONT_ERROR } from '@/shared/const/error/error-info';
import { CONTRACT_STATUS } from '@/shared/const/gamewith/contract-status';
import { TRANSITION_SOURCE_SERVICE, UA_TYPE } from '@/shared/const/service-type';
import { SpfApiService } from '@/shared/services/api/spf-api-service';
import { AuthService } from '@/shared/services/auth/auth-service';
import { convertKanaAndAscii } from '@/shared/util/change-to-full-width-characters';
import { convertFullToHalf } from '@/shared/util/change-to-half-width-characters';
import { getPropertyIdOnPortasDB } from '@/shared/util/func-get-property-id-on-portas-db';
import { isValidMonthAndDatePairs } from '@/shared/util/func-is-valid-month-and-date-pairs';
import { isPlatformDependentCharacters } from '@/shared/util/input-validator';
import { JudgeParameterDecisionProperty } from '@/shared/util/judge-parameter-decision-property';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import { emailAddressIllegalChar, emailAddressIllegalCharMark, emailAddressMaxLength, fullKanaAndBlank, fullWidth, fullWidthNoNum, hasNoBlank } from '@/shared/util/validators';

/** dataオブジェクトの型  */
type DataType = {
  form: PlatformEntryInputForm; // 会員情報入力フォーム（※様々な改変を経て、現在はバリデーションで使用されているくらい）
  propertyFromTransitionSourceServiceId: Property | null; // 会員ステータス.遷移元サービス情報から所得した物件
  isPropertyRegistered: boolean; // お住まい選択済みで遷移（※使用されていない）
  isReEnter: boolean; // 入力確認画面から戻ってきた場合
  isAfterRegister: boolean; // 該当の建物にお住まいでない方
  searchKey: string; // 名称検索フォーム
  zipcode: string; // 郵便番号検索フォーム
  propertyName: string; // 物件名検索フォーム
  addresses: Address[]; // 住所一覧
  selectedAddress: string; // 選択した住所
  propertyInfoList: Property[]; //物件一覧
  errorMessages: string[];
  errorMessageTitle: string;
  searchErrorMessages: string[];
  searchErrorMessageTitle: string;
  selectRoomNumberList: string[];
  isMounting: boolean; // mounted() 実行中
  isExecuting: boolean; // 確認ボタンクリック後の処理実行中
  existsInParameterDecisionTable: boolean; // パラメータ判定
  isGameWith: boolean; //GW光の登録かどうか
  isDisabled: boolean; //ボタン非活性
  isSearchAddressByZipcodeExecuting: boolean; //郵便番号による住所検索実行中かどうか
  PropertiesPropertyInfo: ExternalSearchPropertiesPropertyInfoDto | null | undefined;
};

/** Input コンポーネント */
export default defineComponent({
  name: 'platform-entry-input',
  components: {
    ErrorMessagesComponent,
    LoadingComponent,
    ResidenceRegistrationController,
  },
  data(): DataType {
    return {
      propertyFromTransitionSourceServiceId: null,
      isPropertyRegistered: false /** お住まい選択済みで遷移 */,
      isReEnter: false /** 入力確認画面から戻ってきた場合 */,
      isAfterRegister: false,
      form: {
        firstName: '',
        lastName: '',
        firstNameKana: '',
        lastNameKana: '',
        mailAddress: '',
        birthYear: '',
        birthMonth: '',
        birthDay: '',
        zipcode1: '',
        zipcode2: '',
        address: '',
        apartmentBlockName: '',
        roomNumber: '',
        addressReflectionFlag: '',
      },
      searchKey: '',
      zipcode: '',
      propertyName: '',
      addresses: [],
      selectedAddress: '',
      propertyInfoList: [],
      errorMessages: [],
      errorMessageTitle: '入力項目にエラーがあります。ご確認をお願いいたします。',
      searchErrorMessages: [],
      searchErrorMessageTitle: '',
      selectRoomNumberList: [],
      isMounting: true,
      isExecuting: false,
      existsInParameterDecisionTable: false,
      isGameWith: false,
      isDisabled: false,
      isSearchAddressByZipcodeExecuting: false,
      PropertiesPropertyInfo: null,
    };
  },
  validations: {
    form: {
      // 姓 + '空白' + 名 が 30文字以内
      firstName: { required, fullWidthNoNum, maxLength: maxLength(28), hasNoBlank },
      lastName: { required, fullWidthNoNum, maxLength: maxLength(28), hasNoBlank },
      // 姓 カタカナ + '空白' + 名 カタカナ が 30文字以内
      firstNameKana: { required, maxLength: maxLength(28), fullKanaAndBlank },
      lastNameKana: { required, maxLength: maxLength(28), fullKanaAndBlank },
      mailAddress: { required, emailAddressMaxLength, emailAddressIllegalChar, emailAddressIllegalCharMark },
      birthYear: { required, numeric, minLength: minLength(4), maxLength: maxLength(4) },
      birthMonth: { required },
      birthDay: { required },
      address: { maxLength: maxLength(30), fullWidth },
      apartmentBlockName: { maxLength: maxLength(30), fullWidth },
      roomNumber: { maxLength: maxLength(29), fullWidth },
    },
  },
  setup: () => ({ v$: useVuelidate() }),
  async mounted(): Promise<void> {
    window.scrollTo(0, 0);

    /** ストアに入力内容が保存してあれば復元する */
    const form = this.$store.getters['platformEntryStore/entryInputForm'];
    const inputPropertyForm: InputPropertyForm | null = this.$store.getters['platformEditStore/inputPropertyForm'];
    const memberStatus: MemberStatus = this.$store.getters['memberStore/memberStatus'];
    form.mailAddress = memberStatus?.emailAddress;
    if (inputPropertyForm) {
      this.isAfterRegister = inputPropertyForm.isAfterRegister;
    }

    if (form) {
      this.form = form;
      if (form.errorMessages && form.errorMessages.length) {
        this.errorMessages = form.errorMessages;
      }

      //Authの種類を取得するgetKindOfAuthを変数に格納する
      const kindOfAuth = await AuthService.getKindOfAuth();

      // Appleの場合、氏名を初期表示して編集可能にし、googleの場合、氏名を初期表示して編集可能にする
      if (!this.form.firstName && kindOfAuth === 'apple') {
        this.form.firstName = await AuthService.getFamilyNameWhenApple();
      } else if (!this.form.firstName && kindOfAuth === 'google') {
        this.form.firstName = await AuthService.getFamilyNameWhenGoogle();
      }

      // Appleの場合、氏名を初期表示して編集可能にし、googleの場合、氏名を初期表示して編集可能にする
      if (!this.form.lastName && kindOfAuth === 'apple') {
        this.form.lastName = await AuthService.getFirstNameWhenApple();
      } else if (!this.form.lastName && kindOfAuth === 'google') {
        this.form.lastName = await AuthService.getGivenNameWhenGoogle();
      }
    }
    if (this.$store.getters['errorMessageStore/errorMessages'] != null) {
      this.errorMessages = this.$store.getters['errorMessageStore/errorMessages'];
      this.errorMessageTitle = this.$store.getters['errorMessageStore/errorMessageTitle'];
      //ストアをリセット
      this.$store.commit('errorMessageStore/errorMessages', null);
      this.$store.commit('errorMessageStore/errorMessageTitle', null);
    }

    /** GW光からの申し込み */
    if (memberStatus.encryptedPrimaryKeyGw) {
      this.isGameWith = true;
      try {
        const gwCustomer = await SpfApiExternalGamewithAccessor.getCustomer(memberStatus.encryptedPrimaryKeyGw);
        if (this.isCancelGamewith(gwCustomer.contractStatus, gwCustomer.portasCloseFlag)) {
          this.errorMessages.push('この「GameWith光顧客番号」は既に解約済みですので、Portasは登録できません。');
          this.errorMessageTitle = '';
          this.isDisabled = true;
          window.scrollTo(0, 0);
          this.isMounting = false;
          return;
        }

        if (this.form.addressReflectionFlag === '') {
          //GW 郵便番号を変換する
          if (!gwCustomer.postalCode) {
            this.form.zipcode1 = '';
            this.form.zipcode2 = '';
          } else {
            // 半角に変換し、ハイフンを除外
            const zipcode = convertFullToHalf(gwCustomer.postalCode).replace(/-/g, '');

            // 半角数字7桁でない場合、空欄で表示
            if (!/^\d{7}$/.test(zipcode)) {
              this.form.zipcode1 = '';
              this.form.zipcode2 = '';
            } else {
              // 3桁と4桁に分けて表示
              const zipcode1 = zipcode.slice(0, 3);
              const zipcode2 = zipcode.slice(3);
              //GW光申込情報の郵便番号を取得
              this.form.zipcode1 = zipcode1;
              this.form.zipcode2 = zipcode2;
            }
          }

          //GW光申込情報のお名前と住所を取得
          this.form.lastName = gwCustomer.firstName;
          this.form.firstName = gwCustomer.lastName;
          this.form.lastNameKana = gwCustomer.firstNameKana;
          this.form.firstNameKana = gwCustomer.lastNameKana;
          this.form.address = (gwCustomer.state ?? '') + (gwCustomer.city ?? '') + (gwCustomer.street ?? '') + (gwCustomer.banchi ?? '');
          this.form.apartmentBlockName = gwCustomer.tatemono;
          this.form.roomNumber = gwCustomer.roomNumber;
        } else {
          this.form.addressReflectionFlag = '';
        }

        this.$store.commit('platformEntryStore/primaryKeyGamewith', gwCustomer.customerNumber);
      } catch (e: any) {
        if (e.response?.status == '500') {
          this.errorMessages.push(
            'アクセスいただいたURLに不備があります。GameWith光インターネットサービスお申し込み後にGameWithより送信されたメールをご確認の上、メールに記載のURLにアクセスしてください。'
          );
        } else if (e.response?.status == '503') {
          this.errorMessages.push(
            'Portasをご利用いただきありがとうございます。ただいまシステムメンテナンスを行っております。ご不便をおかけし申し訳ございませんが、メンテナンス終了後に改めてアクセスいただきますようお願いいたします。'
          );
        } else {
          throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.GAME_WITH.CONTRACTOR_INFO);
        }
        //フォームの初期値を空にする
        this.form.lastName = '';
        this.form.firstName = '';
        this.form.lastNameKana = '';
        this.form.firstNameKana = '';
        this.form.address = '';
        this.form.apartmentBlockName = '';
        this.form.addressReflectionFlag = '';
        this.form.roomNumber = '';
        this.errorMessageTitle = '';
        this.isDisabled = true;
        window.scrollTo(0, 0);
        this.isMounting = false;
        return;
      }
    }
    this.propertyFromTransitionSourceServiceId = await this.getpropertyFromTransitionSourceServiceId(memberStatus);

    this.$nextTick(() => {
      this.isMounting = false;
    });
  },
  methods: {
    /**
     * 住所検索ボタン
     */
    async searchAddressByZipcode() {
      if (this.isSearchAddressByZipcodeExecuting) {
        return;
      }
      this.isSearchAddressByZipcodeExecuting = true;
      const zipCode = `${this.form.zipcode1}-${this.form.zipcode2}`;
      this.errorMessages = [];
      const ZIP_CODE_REGEX = /^[0-9]{3}-[0-9]{4}$/;
      // 形式チェック
      if (!ZIP_CODE_REGEX.test(zipCode)) {
        this.errorMessages.push('郵便番号が正しくありません。');
      }
      if (this.errorMessages.length > 0) {
        window.scrollTo(0, 0);
        this.isSearchAddressByZipcodeExecuting = false;
        return;
      }
      try {
        const resultList = await SpfApiService.searchAddresses(zipCode);
        if (resultList.length > 0) {
          const result = resultList[0];
          this.form.address = `${result.address1}${result.address2}${result.address3}`;
        } else {
          this.errorMessages.push('郵便番号が正しくありません。');
          window.scrollTo(0, 0);
        }
      } catch (e) {
        // エラーのときは何もしない
      }
      this.isSearchAddressByZipcodeExecuting = false;
    },
    /**
     * 入力項目を表示する
     */
    getItem(itemName: string): string {
      return this.$store.getters['platformEntryStore/entryInputFormItem'](itemName);
    },
    /**
     * 入力内容をストアに反映する
     */
    updateItem($event: any, itemName: string): void {
      this.$store.commit('platformEntryStore/updateFormItem', { value: $event.target.value, itemName: itemName });
    },
    /** フォームのバリデーション */

    validate(): boolean {
      this.form = this.$store.getters['platformEntryStore/entryInputForm'];

      if (this.v$.form.firstName?.required.$invalid || this.v$.form.lastName?.required.$invalid) {
        this.errorMessages.push('お名前を入力してください。');
      } else if (isPlatformDependentCharacters(this.form.firstName!) || isPlatformDependentCharacters(this.form.lastName!)) {
        this.errorMessages.push('恐れ入りますが、お名前に機種依存文字はご登録いただけません。');
      } else if (this.v$.form.firstName?.fullWidthNoNum.$invalid || this.v$.form.lastName?.fullWidthNoNum.$invalid) {
        this.errorMessages.push('お名前の形式が正しくありません。');
      } else if (this.v$.form.firstName?.hasNoBlank.$invalid || this.v$.form.lastName?.hasNoBlank.$invalid) {
        this.errorMessages.push('お名前に空白を含めないようにしてください。');
      }
      if (this.isOver31Characters()) {
        this.errorMessages.push('お名前は姓と名合わせて全角29文字以内で入力してください。');
      }
      if (this.v$.form.firstNameKana?.required.$invalid || this.v$.form.lastNameKana?.required.$invalid) {
        this.errorMessages.push('フリガナを入力してください。');
      } else if (this.v$.form.firstNameKana?.fullKanaAndBlank.$invalid || this.v$.form.lastNameKana?.fullKanaAndBlank.$invalid) {
        this.errorMessages.push('フリガナの形式が正しくありません。');
      }
      if (this.isOver31CharactersKatakana()) {
        this.errorMessages.push('フリガナはセイとメイ合わせて全角29文字以内で入力してください。');
      }
      if (this.v$.form.mailAddress?.required.$invalid) {
        this.errorMessages.push('メールアドレスを入力してください。');
      } else if (this.v$.form.mailAddress?.emailAddressMaxLength.$invalid) {
        this.errorMessages.push('メールアドレスは48文字以内で入力してください。');
      } else if (this.v$.form.mailAddress?.emailAddressIllegalChar.$invalid || this.v$.form.mailAddress?.emailAddressIllegalCharMark.$invalid) {
        this.errorMessages.push('メールアドレスの形式が正しくありません。');
      }

      // // 郵便番号
      const myformCode = `${this.form.zipcode1}-${this.form.zipcode2}`;
      const ZIP_CODE_REGEX = /^(|[0-9]{3}-[0-9]{4})$/;
      // // 形式チェック
      if (!ZIP_CODE_REGEX.test(myformCode) && this.form.zipcode1 !== '' && this.form.zipcode2 !== '') {
        this.errorMessages.push('郵便番号が正しくありません。');
      }
      // 住所
      if (this.v$.form.address?.maxLength.$invalid) {
        this.errorMessages.push('住所は全角30文字以内で入力してください。');
      }
      // 建物名
      if (this.v$.form.apartmentBlockName?.maxLength.$invalid) {
        this.errorMessages.push('建物名は全角30文字以内で入力してください。');
      }
      // 棟・部屋番号
      if (this.v$.form.roomNumber?.maxLength.$invalid) {
        this.errorMessages.push('棟・部屋番号は全角29文字以内で入力してください。');
      }

      const checkedBirthDate = new Date('01/01/1753');
      let year = Number(this.form.birthYear!);
      let month = Number(this.form.birthMonth!);
      let day = Number(this.form.birthDay!);

      let getUserBirthday = new Date(Date.UTC(year, month - 1, day));

      if (this.v$.form.birthYear?.required.$invalid || this.v$.form.birthMonth?.required.$invalid || this.v$.form.birthDay?.required.$invalid) {
        this.errorMessages.push('生年月日を入力してください。');
      } else if (this.v$.form.birthYear?.minLength.$invalid || this.v$.form.birthYear?.maxLength.$invalid || this.v$.form.birthYear?.numeric.$invalid) {
        this.errorMessages.push('生年月日の形式が正しくありません。');
      } else if (!isValidMonthAndDatePairs(Number(this.form.birthYear), Number(this.form.birthMonth), Number(this.form.birthDay))) {
        this.errorMessages.push('生年月日の形式が正しくありません。');
      } //UCOM側のバリデーション仕様(1753年1月1日以降を許容する) に合わせる。
      else if (checkedBirthDate > getUserBirthday) {
        this.errorMessages.push('生年月日に正しい情報を入力してください。');
      } else {
        const birthDate = new Date(this.form.birthYear + '-' + this.form.birthMonth + '-' + this.form.birthDay);
        if (birthDate > new Date()) {
          this.errorMessages.push('生年月日の形式が正しくありません。');
        }
      }

      if (this.errorMessages.length > 0) {
        return false;
      }
      return true;
    },

    /** 次へボタン押下時： フォームの値をストアに保存し入力確認画面に遷移する(お住まいを登録した場合) */
    async onNext(): Promise<void> {
      if (this.isExecuting) {
        return;
      }
      this.isExecuting = true;

      this.errorMessages = [];

      if (this.form.address != undefined) {
        this.form.address = convertKanaAndAscii(this.form.address);
        if (this.v$.form.address?.fullWidth.$invalid) {
          this.errorMessages.push('住所は全角30文字以内で入力してください。');
        }
      }
      if (this.form.apartmentBlockName != undefined) {
        this.form.apartmentBlockName = convertKanaAndAscii(this.form.apartmentBlockName);
        if (this.v$.form.apartmentBlockName?.fullWidth.$invalid) {
          this.errorMessages.push('建物名は全角30文字以内で入力してください。');
        }
      }
      if (this.form.roomNumber != undefined) {
        this.form.roomNumber = convertKanaAndAscii(this.form.roomNumber);
        if (this.v$.form.roomNumber?.fullWidth.$invalid) {
          this.errorMessages.push('棟・部屋番号は全角29文字以内で入力してください。');
        }
      }

      this.validate();
      this.form = this.$store.getters['platformEntryStore/entryInputForm'];

      // ストアが 'editStore' と 'entryStore' と 2つあるが、検索した物件はすべて 'editStore' に入る
      const inputPropertyForm: InputPropertyForm | null = this.$store.getters['platformEditStore/inputPropertyForm'];

      if (!inputPropertyForm?.selectedProperty) {
        this.errorMessages.push('お住まいを選択してください。');
      }

      if (this.errorMessages.length > 0) {
        // エラーメッセージを見せるために画面最上部にスクロール
        // this.validate() の中でも errorMessages に push されている
        window.scrollTo(0, 0);
        this.isExecuting = false;
        return;
      }

      // お住まいのラジオボタンを押下時にサービス選択画面から戻ってきた場合、updateRadiosの処理を行わないようにする処理
      this.form.addressReflectionFlag = 'false';

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/platform/entry/select-portas-service').catch((error: any) => {
        checkRouterError(error);
      });
    },
    /** 次へボタン押下時： フォームの値をストアに保存し入力確認画面に遷移する(お住まいを登録しない場合) */
    async onNextWithoutProperty(): Promise<void> {
      if (this.isExecuting) {
        return;
      }
      this.isExecuting = true;

      this.errorMessages = [];

      if (this.form.address != undefined) {
        this.form.address = convertKanaAndAscii(this.form.address);
        if (this.v$.form.address?.fullWidth.$invalid) {
          this.errorMessages.push('住所は全角30文字以内で入力してください。');
        }
      }
      if (this.form.apartmentBlockName != undefined) {
        this.form.apartmentBlockName = convertKanaAndAscii(this.form.apartmentBlockName);
        if (this.v$.form.apartmentBlockName?.fullWidth.$invalid) {
          this.errorMessages.push('建物名は全角30文字以内で入力してください。');
        }
      }
      if (this.form.roomNumber != undefined) {
        this.form.roomNumber = convertKanaAndAscii(this.form.roomNumber);
        if (this.v$.form.roomNumber?.fullWidth.$invalid) {
          this.errorMessages.push('棟・部屋番号は全角29文字以内で入力してください。');
        }
      }

      if (this.validate()) {
        this.$store.commit('platformEntryStore/entryInputForm', this.form);
        this.$store.commit('platformEditStore/inputPropertyForm', null);
        this.setStoreOfIsAfterRegister(true);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        await this.$router.push('/platform/entry/select-portas-service').catch((error: any) => {
          checkRouterError(error);
        });
      } else {
        // エラーメッセージを見せるために画面最上部にスクロール
        window.scrollTo(0, 0);
        this.isExecuting = false;
        return;
      }
    },
    /**
     * store に 該当の建物にお住まいでない方 の状態を保存する
     */
    setStoreOfIsAfterRegister(value: boolean): void {
      this.$nextTick(() => {
        const residenceRegistrationController = this.$refs.residenceRegistrationController as InstanceType<typeof ResidenceRegistrationController>;
        residenceRegistrationController.updateStoreOfIsAfterResiter(value);
      });
    },
    /** 次へボタン押下時:GW光からの登録（物件登録なし） */
    async onNextWithoutPropertyByGW() {
      if (this.isExecuting) {
        return;
      }
      this.isExecuting = true;

      this.errorMessages = [];

      if (this.form.address != undefined) {
        this.form.address = convertKanaAndAscii(this.form.address);
        if (this.v$.form.address?.fullWidth.$invalid) {
          this.errorMessages.push('住所は全角30文字以内で入力してください。');
        }
      }
      if (this.form.apartmentBlockName != undefined) {
        this.form.apartmentBlockName = convertKanaAndAscii(this.form.apartmentBlockName);
        if (this.v$.form.apartmentBlockName?.fullWidth.$invalid) {
          this.errorMessages.push('建物名は全角30文字以内で入力してください。');
        }
      }
      if (this.form.roomNumber != undefined) {
        this.form.roomNumber = convertKanaAndAscii(this.form.roomNumber);
        if (this.v$.form.roomNumber?.fullWidth.$invalid) {
          this.errorMessages.push('棟・部屋番号は全角29文字以内で入力してください。');
        }
      }

      if (this.validate()) {
        this.$store.commit('platformEntryStore/entryInputForm', this.form);
        this.$store.commit('platformEditStore/inputPropertyForm', null);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        await this.$router.push('/platform/entry/select-portas-service').catch((error: any) => {
          checkRouterError(error);
        });
      } else {
        // エラーメッセージを見せるために画面最上部にスクロール
        window.scrollTo(0, 0);
        this.isExecuting = false;
        return;
      }

      // サービス選択画面から戻ってきた場合、GWの自動入力の処理を行わないようにする処理
      this.form.addressReflectionFlag = 'false';
    },
    async onNextWithpropertyFromTransitionSourceServiceId(): Promise<void> {
      if (this.isExecuting) {
        return;
      }
      this.isExecuting = true;

      this.errorMessages = [];

      if (this.form.address != undefined) {
        this.form.address = convertKanaAndAscii(this.form.address);
        if (this.v$.form.address?.fullWidth.$invalid) {
          this.errorMessages.push('住所は全角30文字以内で入力してください。');
        }
      }
      if (this.form.apartmentBlockName != undefined) {
        this.form.apartmentBlockName = convertKanaAndAscii(this.form.apartmentBlockName);
        if (this.v$.form.apartmentBlockName?.fullWidth.$invalid) {
          this.errorMessages.push('建物名は全角30文字以内で入力してください。');
        }
      }
      if (this.form.roomNumber != undefined) {
        this.form.roomNumber = convertKanaAndAscii(this.form.roomNumber);
        if (this.v$.form.roomNumber?.fullWidth.$invalid) {
          this.errorMessages.push('棟・部屋番号は全角29文字以内で入力してください。');
        }
      }

      if (this.validate()) {
        this.$store.commit('platformEntryStore/entryInputForm', this.form);
        this.$store.commit('platformEntryStore/propertyFromTransitionSourceServiceId', this.propertyFromTransitionSourceServiceId);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        await this.$router.push('/platform/entry/select-portas-service').catch((error: any) => {
          checkRouterError(error);
        });
      } else {
        // エラーメッセージを見せるために画面最上部にスクロール
        window.scrollTo(0, 0);
        this.isExecuting = false;
        return;
      }
    },
    changeIsAfterRegister(value: boolean): void {
      this.isAfterRegister = value;
    },
    /**
     * お住まいのラジオボタン押下時に契約者住所反映
     */
    updateRadios(value: boolean) {
      const entryInputForm = this.$store.getters['platformEntryStore/entryInputForm'];
      if (this.form.addressReflectionFlag === '') {
        const inputPropertyForm: InputPropertyForm | null = this.$store.getters['platformEditStore/inputPropertyForm'];
        if (inputPropertyForm?.selectedProperty != null && inputPropertyForm?.selectedProperty.apartmentAddress != undefined) {
          if (inputPropertyForm?.selectedProperty.zipcode && inputPropertyForm?.selectedProperty.zipcode.includes('-')) {
            const zipcodeSplitByHyphen = inputPropertyForm?.selectedProperty.zipcode.split('-');
            this.form.zipcode1 = zipcodeSplitByHyphen[0];
            this.form.zipcode2 = zipcodeSplitByHyphen[1];
          } else if (entryInputForm?.zipcode1 === '' && entryInputForm?.zipcode2 === '') {
            this.form.zipcode1 = '';
            this.form.zipcode2 = '';
          }
          this.form.address = inputPropertyForm?.selectedProperty.apartmentAddress;
          this.form.apartmentBlockName = inputPropertyForm?.selectedProperty.apartmentName;
        }
      } else {
        this.form.addressReflectionFlag = '';
      }
    },
    isOver31Characters(): boolean {
      const array = [this.form.firstName, '　', this.form.lastName];
      return array.join('').length >= 31;
    },
    isOver31CharactersKatakana(): boolean {
      const array = [this.form.firstNameKana, '　', this.form.lastNameKana];
      return array.join('').length >= 31;
    },
    /**
     * 会員ステータス.遷移元サービス と 会員ステータス.遷移元サービス物件ID に値が存在するとき、物件情報を取得する
     *
     * 仕様上、この処理により 物件情報 が取得できるのは、次の条件を満たす場合のみ
     *
     * ・ /login-forwarding?from=Five.A&apid=AA0146&processtype=regist のような、物件基本情報取得API で存在が確認できるFive.A物件
     *
     * 上の条件を満たす場合、会員ステータス.遷移元サービス と 会員ステータス.遷移元サービス物件ID に値が存在している
     */
    async getpropertyFromTransitionSourceServiceId(memberStatus: MemberStatus): Promise<Property | null> {
      if (memberStatus.transitionSourceService && memberStatus.transitionSourceServiceId) {
        //  パラメータ判定テーブルにある物件か確認する
        if (await JudgeParameterDecisionProperty.existsOnPortasDB(memberStatus?.transitionSourceServiceId)) {
          // パラメータ判定テーブルにある物件の場合
          this.$data.existsInParameterDecisionTable = true;
        }

        if (memberStatus.transitionSourceService === TRANSITION_SOURCE_SERVICE.FIVE_A) {
          // 仕様上 Five.A物件の場合のみ

          const propertyIdOnPortasDB = await getPropertyIdOnPortasDB(memberStatus.transitionSourceServiceId, UA_TYPE.FIVE_A);
          const property = await SpfApiService.findPropertyById(propertyIdOnPortasDB);

          return property;
        }
      }
      return null;
    },
    /**
     * GW会員の契約状態チェック
     * 「キャンセル」、「廃止」/「解約」＆閉鎖フラグがTRUEの場合true
     */
    isCancelGamewith(status: string, closedFlag: boolean): boolean {
      if (status == CONTRACT_STATUS.APPLICATION_CANCELED) {
        return true;
      }
      if (status == CONTRACT_STATUS.ABOLITION || status == CONTRACT_STATUS.CANCELED) {
        if (closedFlag == true) {
          return true;
        }
      }
      return false;
    },
  },
});
</script>
