<template>
  <div>
    <LoadingComponent v-if="isMounting || isExecutingNavigateToLocation" />
    <main class="underlayer-main">
      <h1>Portas新規会員登録 Portas会員限定 推奨サービスのご案内</h1>
    </main>

    <div class="contents">
      <ul class="breadcrumb">
        <li><router-link to="/">トップページ</router-link></li>
        <li>新規会員登録</li>
        <li>Portas会員限定 推奨サービスのご案内</li>
      </ul>

      <ul class="application-flow grid pc-grid5 sp-grid5 gap10">
        <li>お申し込み受付</li>
        <li>お客様情報入力</li>
        <li class="stay">Portas会員限定 推奨サービスのご案内</li>
        <li>Portas新規会員登録・サービスお申し込み最終確認</li>
        <li>完了</li>
      </ul>

      <div class="my-service-margin-top">
        <p>Portas会員様向けの限定サービスです。インターネットサービスを安心してご利用いただくために、是非この機会にお申し込みください。</p>
      </div>

      <div class="blc my-service-margin-top">
        <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="Portasサービス選択" />Portas会員限定 推奨サービスのご案内（お申し込みは任意）</h2>
      </div>

      <section class="my-portas-service imageContents">
        <div class="my-portas-service__description">
          <table class="table-type2">
            <tbody>
              <tr class="invisible-border"></tr>
              <tr>
                <td>
                  <div class="my-portas-service__description">
                    <div class="my-portas-service__description-detail">
                      <div>
                        <div class="my-service-image">
                          <img class="sp" src="../../../images/portas-subscription/retenA_SP0130.png" />
                          <img class="pc" src="../../../images/portas-subscription/retenA_pc0130.png" />
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <h2 class="underlayer-h2 title-border">あんしんサポートセットプラス <span class="price-next-to-title">月額1,496円（税込）</span></h2>
                  <div class="my-portas-service__description">
                    <div class="my-portas-service__description-detail">
                      <div>
                        <div class="my-service-image">
                          <img class="sp" src="../../../images/portas-subscription/retenB_sp0130.png" />
                          <img class="pc" src="../../../images/portas-subscription/retenB_pc0130.png" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="my-portas-service__terms-area">
                    <p>
                      <a
                        class="link"
                        href="https://www.arteria-net.com/portas/service/setplus?utm_source=Portas&utm_medium=about&utm_campaign=setplus&utm_content=confirmation_portop"
                        target="setplus"
                      >
                        サービス詳細→
                      </a>
                    </p>
                    <p>
                      <a class="link" href="https://www.arteria-net.com/portas/service/terms/marge/setplus.pdf" target="setplusPdf"> ご利用規約→ </a>
                    </p>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="spContents">
                  <div class="my-portas-service__description-detail campaign-title-margin">
                    <h2 class="campaign-text-margin">毎月抽選で5名様に10,000円相当の選べるギフトが当たる！</h2>
                    <p>キャンペーン期間：2024年11月1日～2025年10月31日</p>
                  </div>
                  <div class="campaign-text-mini-margin">「あんしんサポートセットプラス」お申し込みの際、本キャンペーン応募規約に同意いただいたこととなります。<br /></div>
                  <p class="campaign-text-mini-margin">
                    キャンペーン詳細ならびに応募規約は<a :href="portasMonthlyCampaignUrl" target="portasMonthlyCampaignUrl" class="link">こちら</a>からご確認ください。
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
          <div>
            <p>無料期間終了後は自動的にご利用料金が発生いたします。</p>
            <p class="red">お申し込み後のキャンセル・返金はできません。</p>
            <p>Portas(ポルタス)ご登録後の「Portasマイページ」から、セット内サービスをいつでもご解約いただけます。</p>
          </div>
          <div class="portas-service-attention">
            <p class="checkbox-margin">Portas会員登録に併せて、あんしんサポートセットプラスの申し込み手続きを行う方は、チェックボックスにチェックして「次へ」を押してください。</p>
          </div>

          <div class="my-portas-service__checkbox">
            <div>
              <div>
                <label> <input type="checkbox" v-model="isApplyForPortasService" />Portas会員登録に併せて、あんしんサポートセットプラスの申し込み手続きに移る</label>
              </div>
            </div>
          </div>

          <div class="notorder">
            <h4>あんしんサポートセットプラスが不要な方は、チェックを入れずに、そのまま「次へ」を押してください。</h4>
          </div>

          <div class="blc">
            <div class="button-style">
              <div class="btn-area my-portas-service__button-wrapper">
                <button class="btn btn05 bs" @click="$router.go(-1)"><i class="material-icons link link-icon">west</i>戻る</button>
                <button class="btn btn01 bs" @click="onNext()">次へ</button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.button-style {
  padding-top: none;
}
.blc {
  margin: 0px auto 0;
}
.service-set-title {
  margin-left: 0.5em;
}
.table-type2 {
  margin: none;
}
.underlayer-h2 {
  padding: 0 0 0 0;
}

.imageContents {
  max-width: 975px;
}

.price-next-to-title {
  margin-left: 40px;
}

.notSelectionPortas {
  margin: 0 0 10px;
}

.disabled-link {
  pointer-events: none;
  cursor: default;
}

.my-service-margin-top {
  margin-top: 32px;
}

.my-service-image {
  text-align: center;

  & img {
    width: 100%;
    margin: 0 auto;

    &.sp {
      display: block;
      max-width: 760px;
    }

    &.pc {
      display: none;
      max-width: 960px;
    }
  }
}
.checkbox-margin {
  margin: 0;
  font-weight: bold;
}

.table-type2 {
  border-top: none;
}

.underlayer-h2 {
  border-left: none !important;
}

.campaign-text-mini-margin {
  margin-bottom: 3px;
}

.campaign-text-margin {
  margin-bottom: 5px;
}
.campaign-title-margin {
  margin-bottom: 20px;
}

.invisible-border {
  border-bottom: 0;
}

.title-border {
  border-left: 3px solid #cf1225 !important;
  padding-left: 15px !important;
}

.portas-service-attention {
  margin-top: 32px;
}

.notorder {
  text-align: center;
  margin-top: 5px;
  margin-left: 20px;
}

@media only screen and (min-width: 768px) {
  .my-service-image {
    & img {
      margin: none;
      &.sp {
        display: none;
      }

      &.pc {
        display: block;
      }
    }
  }
}

.price-next-to-title {
  margin-left: 8px;
  font-size: 18px;
  font-weight: 400;
}

.my-portas-service {
  &__title {
    font-size: 16px;
  }

  &__checkbox {
    padding-top: 40px;

    & div {
      display: flex;
      flex-direction: row;
      justify-content: space-around;

      & div {
        position: relative;

        & label {
          font-size: 14px;
          padding-left: 40px;

          & input {
            position: absolute;
            top: 4px;
            left: 16px;
            transform: scale(1.5);
          }
        }
      }
    }
  }

  &__description {
    & h3.description-title {
      font-size: 13px;
    }

    & p.service-name {
      font-size: 13px;
    }
  }

  &__description-detail {
    & h4.product-name {
      font-size: 13px;
    }

    & p.product-description {
      line-height: 1.6;
      letter-spacing: 0.8px;
    }
  }

  &__terms-area {
    margin-top: 8px;
  }

  &__button-wrapper {
    margin-top: 0;
    padding-top: 40px;
  }
}

@media only screen and (min-width: 768px) {
  .my-portas-service {
    &__title {
      font-size: 20px;
    }

    &__description {
      & h3.description-title {
        margin-bottom: 4px;
      }
    }

    &__description-detail {
      margin-top: 16px;
    }
  }
}

@media only screen and (max-width: 767px) {
  .table-type2 td:first-child {
    display: block;
    padding: none;
    margin-bottom: 10px;
    margin-top: 10px;
  }
  .spContents {
    padding-right: 0 !important;
  }

  .price-next-to-title {
    text-indent: -9px;
    display: block;
  }
}

@media only screen and (min-width: 1000px) {
  .my-portas-service {
    &__checkbox {
      & div {
        & div {
          & label {
            font-size: 16px;

            & input {
              top: 5px;
            }
          }
        }
      }
    }
  }
}

button[disabled] {
  cursor: not-allowed;
}
</style>

<script lang="ts">
import { defineComponent } from 'vue';

import { DataInconsistencyFrontError } from '@/shared/classes/error/data-inconsistency-front-error';
import { PlatformEntryInputForm } from '@/shared/classes/platform/entry-input-form';
import { Member } from '@/shared/classes/spf-api/member';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { FRONT_ERROR_INFO_DATA_INCONSISTENCT } from '@/shared/const/error/error-info';
import { PRODUCT_ID_WITH_SPECIFIC_USE } from '@/shared/const/portas-subscription/product-id-with-specific-use';
import { checkRouterError } from '@/shared/util/router-navigation-func';

export default defineComponent({
  name: 'platform-entry-select-portas-service',
  components: {
    LoadingComponent,
  },
  data: () => ({
    isMounting: true,
    isApplyForPortasService: false,
    isExecutingNavigateToLocation: false,
    member: null as Member | null,
    errorMessages: [] as string[],
    entryInputForm: null as PlatformEntryInputForm | null,
    selectedServiceOption: 0,
    portasMonthlyCampaignUrl: process.env.VUE_APP_MONTHLY_CAMPAIN_FOR_ENTRY_PORTAS_URL,
  }),
  mounted() {
    // /platform/entry/confirm から戻ってきたときの処理
    this.selectedServiceOption = this.$store.getters['platformEntryStore/portasServiceKind'] || 0;
    this.entryInputForm = this.$store.getters['platformEntryStore/entryInputForm'];

    if (this.entryInputForm) {
      if (this.entryInputForm.wishToContractProductIdList && this.entryInputForm.wishToContractProductIdList.length > 0) {
        this.isApplyForPortasService = true;
      }
      this.entryInputForm.wishToContractProductIdList = [];
    } else {
      this.$router.push('/platform/entry/input').catch((error: any) => {
        checkRouterError(error);
      });
      return;
    }
    this.member = this.$store.getters['memberStore/member'];
    this.isMounting = false;
  },

  methods: {
    async onNext(): Promise<void> {
      if (this.isExecutingNavigateToLocation) {
        return;
      }
      this.isExecutingNavigateToLocation = true;

      // エラーメッセージを初期化
      this.errorMessages = [];

      if (this.isApplyForPortasService) {
        const entryInputForm: PlatformEntryInputForm | null = this.$store.getters['platformEntryStore/entryInputForm'];
        if (!entryInputForm) {
          throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.DATA_INCONSISTENT);
        } else if (!entryInputForm.address || !entryInputForm.zipcode1 || !entryInputForm.zipcode2) {
          // エラーメッセージをストアに格納
          this.errorMessages.push('Portasサービスのお申し込みには郵便番号・住所のご登録が必要です。Portasサービス契約住所を入力してください。');
          this.$store.commit('errorMessageStore/errorMessages', this.errorMessages);

          // 遷移元画面にリダイレクト
          await this.$router.push('/platform/entry/input').catch((e) => {
            checkRouterError(e);
          });

          this.isExecutingNavigateToLocation = false;
          return;
        }

        if (this.isApplyForPortasService) {
          entryInputForm.wishToContractProductIdList = PRODUCT_ID_WITH_SPECIFIC_USE.USE_IN_PORTAS_ENTRY;
          this.$store.commit('platformEntryStore/entryInputForm', entryInputForm);
        }
      }

      this.$store.commit('platformEntryStore/portasServiceKind', this.selectedServiceOption);

      await this.$router.push('/platform/entry/confirm').catch((e) => {
        checkRouterError(e);
      });
      this.isExecutingNavigateToLocation = false;
    },
  },
});
</script>
