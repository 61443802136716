<template>
  <div class="accept-email">
    <!-- underlayer-main -->
    <main class="underlayer-main">
      <h1>Portas新規会員登録 お申し込み受付完了</h1>
    </main>
    <!-- /underlayer-main -->
    <!-- contents -->
    <div class="contents">
      <!-- breadcrumb -->
      <ul class="breadcrumb">
        <li v-if="!isGameWith"><router-link to="/">トップページ</router-link></li>
        <li>新規会員登録</li>
        <li>お申し込み受付完了</li>
      </ul>
      <!-- /breadcrumb -->
      <!-- application-flow -->
      <ul class="application-flow grid pc-grid5 sp-grid5 gap10">
        <li class="stay">お申し込み受付完了</li>
        <li>お客様情報入力</li>
        <li>Portas会員限定 推奨サービスのご案内</li>
        <li>Portas新規会員登録・サービスお申し込み最終確認</li>
        <li>完了</li>
      </ul>
      <!-- /application-flow -->
      <!-- blc -->
      <div class="blc">
        <p>ご入力いただいたメールアドレスへメールを送信いたしました。</p>
        <p>メールに記載されたURL（アクセス有効期限は発行から24時間以内になります。）から、お客様情報入力ページを開き、お申し込みを進めてください。</p>
        <br />
        <p><b>メールが届かない場合</b></p>
        <p>フリーメール（Gmail、Yahoo!メール、hotmailなど）やご利用のメール環境によっては、お送りするメールが迷惑メール扱いになる場合がございます。</p>
        <p>メールが届かない場合、通常の受信ボックス以外の迷惑メールフォルダ等もご確認ください。</p>
        <p>迷惑メールフィルタ機能により受信がブロックされている可能性がある場合、受信メールフィルタ設定で「@portas.jp」からの受信を許可していただく必要がございます。</p>
        <br />
        <p>この画面はブラウザの閉じるボタンで閉じてください。</p>
      </div>
      <!-- /blc -->
    </div>
    <!-- /contents -->
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import { MemberStatus } from '@/shared/classes/spf-api/member-status';
import { checkRouterError } from '@/shared/util/router-navigation-func';

/** コンポーネント */
export default defineComponent({
  name: 'accept-email',
  data: () => ({
    isGameWith: false,
  }),
  mounted() {
    const memberStatus: MemberStatus = this.$store.getters['memberStore/memberStatus'];
    if (memberStatus.encryptedPrimaryKeyGw) {
      this.isGameWith = true;
    }
  },
  methods: {
    async toHome(): Promise<void> {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/').catch((error: any) => {
        checkRouterError(error);
      });
    },
  },
});
</script>
