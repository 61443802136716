<template>
  <div class="completed">
    <LoadingComponent v-if="isRouting || isMounting" />
    <main class="underlayer-main">
      <h1>Portas新規会員登録 完了</h1>
    </main>

    <div class="contents">
      <ul class="breadcrumb">
        <li><router-link to="/">トップページ</router-link></li>
        <li>新規会員登録</li>
        <li>完了</li>
      </ul>

      <ul
        class="application-flow grid pc-grid5 sp-grid5 gap10"
        v-if="!(existsInParameterDecisionTable && (hasTransitionSourceServiceIdUaTypeEmansion || hasTransitionSourceServiceIdUaTypeUcom))"
      >
        <li>お申し込み受付</li>
        <li>お客様情報入力</li>
        <li>Portas会員限定 推奨サービスのご案内</li>
        <li>Portas新規会員登録・サービスお申し込み最終確認</li>
        <li class="stay">完了</li>
      </ul>

      <div class="blc">
        <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="Portas新規会員登録 完了" />Portas新規会員登録 完了</h2>
        <p>Portas（ポルタス）への新規会員登録をありがとうございます。</p>
        <p>Portasへの会員登録が完了しました。</p>
      </div>

      <!-- /platform/entry/select-portas-service にて選択した商品の契約が成立したとき -->
      <div class="blc" v-if="contractedProductIdList && contractedProductIdList.length > 0">
        <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="あんしんサポートセットプラスお申込み完了" />あんしんサポートセットプラスお申し込み完了</h2>
        <p>ノートン&trade; セキュリティ + Internet SagiWall for マルチデバイス + Wi-Fi&Device Support + AdGuard のお申し込みが完了しました。</p>
        <p>無料期間中にぜひ、ご活用ください。</p>
      </div>

      <div class="blc">
        <div class="top-entry-unext">
          <div class="my-unext-area">
            <section class="my-unext-text">
              <div class="my-service-image">
                <router-link to="/platform/my-page/products/entry-input?product1=4&utm_source=Portas&utm_medium=completed&utm_campaign=u-next&utm_content=u-next_confirmation">
                  <img class="sp" src="../../../images/connectixToUnext_sp.png" />
                  <img class="pc" src="../../../images/connectixToUnext_pc.png" />
                </router-link>
              </div>
            </section>
          </div>
        </div>
      </div>

      <div class="blc" v-if="contractedProductIdList && contractedProductIdList.length > 0">
        <p class="text-align-center">「トップページへ」を押してPortasをご利用ください</p>
        <div class="btn-area">
          <router-link to="/" custom v-slot="{ navigate }">
            <button class="btn btn01 bs" @click="navigate">トップページへ<i class="material-icons link link-icon">east</i></button>
          </router-link>
        </div>
      </div>
      <div v-else>
        <div class="blc">
          <div class="btn-area">
            <button class="btn btn01 bs" @click="onRegisterPayment()">Portasサービス お支払い方法登録へ<i class="material-icons link link-icon">east</i></button>
          </div>
        </div>
        <div class="blc">
          <div class="btn-area">
            <button class="btn btn04 bs" @click="onNext()">今回はスキップ<i class="material-icons link link-icon">east</i></button>
          </div>
        </div>
      </div>

      <div class="blc" v-if="existsInParameterDecisionTable && (hasTransitionSourceServiceIdUaTypeEmansion || hasTransitionSourceServiceIdUaTypeUcom)">
        <div>
          <h4 class="underlayer-h4">メールが届かない場合</h4>
          <ul class="ul-basic01">
            <li>
              フリーメール（Gmail、Yahoo!メール、Outlook、hotmailなど）やご利用のメール環境によっては、お送りするメールが迷惑メール扱いになる場合がございます。<br />
              メールが届かない場合、通常の受信ボックス以外の迷惑メールフォルダ、ゴミ箱フォルダ等もご確認ください。<br />
              受信したメールが迷惑メールフォルダ、ゴミ箱フォルダへ振り分けられると、数日中に自動的に削除されてしまう場合がございます。
              セキュリティ対策として、メールが受信フォルダに届く前に自動で削除されてしまう場合もございますので、ご注意ください。
            </li>
            <li>以下のような原因も考えられますので、心当たりのある場合はご確認をお願いいたします。</li>
            <ul class="ul-basic01">
              <li>メールアプリ、ソフトの振り分け設定により別のフォルダにメールが入っている。</li>
              <li>メールサーバーに受信拒否設定がしてある。</li>
              <li>お使いのメールアドレスの受信ボックスやサーバーの容量がいっぱいになっている。</li>
              <li>使用していないメールアドレスを誤って入力した。</li>
            </ul>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.text-align-center {
  text-align: center;
}
.top-entry-unext {
  max-width: 960px;
  margin: 0 auto;
}

div.my-unext-area {
  max-width: 960px;
  margin: 0 auto;
}
section.my-unext-text {
  & div.my-title {
    margin-bottom: 16px;
    padding-left: 16px;

    & h2 {
      display: inline-block;
      margin: 0;
      font-size: 20px;
      line-height: 1.6;
      border-bottom: 3px solid #cf1225;
    }
  }
}

.my-service-image {
  text-align: center;

  & img {
    width: 100%;
    margin: 0 auto;

    &.sp {
      display: block;
      max-width: 760px;
    }

    &.pc {
      display: none;
      max-width: 960px;
    }
  }
}

@media only screen and (min-width: 768px) {
  .my-service-image {
    & img {
      margin: none;
      &.sp {
        display: none;
      }

      &.pc {
        display: block;
      }
    }
  }
}
</style>

<script lang="ts">
import { defineComponent } from 'vue';

import { DataInconsistencyFrontError } from '@/shared/classes/error/data-inconsistency-front-error';
import { PlatformEntryInputForm } from '@/shared/classes/platform/entry-input-form';
import { Member } from '@/shared/classes/spf-api/member';
import { MemberStatus } from '@/shared/classes/spf-api/member-status';
import { Property } from '@/shared/classes/spf-api/property';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { FRONT_ERROR_INFO_DATA_INCONSISTENCT } from '@/shared/const/error/error-info';
import { MEMBER_STATUS } from '@/shared/const/member-status';
import { TRANSITION_SOURCE_SERVICE } from '@/shared/const/service-type';
import { JudgeParameterDecisionProperty } from '@/shared/util/judge-parameter-decision-property';
import { checkRouterError } from '@/shared/util/router-navigation-func';

type DataType = {
  memberStatus: MemberStatus | null;
  member: Member | null;
  property: Property | null;
  isRouting: boolean; // onNextをクリックした後、router.pushが行われるまで用
  isMounting: boolean;
  hasTransitionSourceServiceIdUaTypeEmansion: boolean;
  hasTransitionSourceServiceIdUaTypeUcom: boolean;
  existsInParameterDecisionTable: boolean; // パラメータ判定テーブルにある物件
  /**
   * サブスクリプション契約申込APIのレスポンス
   * /platform/entry/select-portas-service にて選択した商品をリクエストパラメータに、サブスクリプション契約申込API を実行
   */
  contractedProductIdList: Array<number> | undefined;
  isGameWith: boolean; //GW光の登録かどうか
};

/** 新規会員登録完了 コンポーネント */
export default defineComponent({
  name: 'platform-entry-completed',
  components: {
    LoadingComponent,
  },
  data(): DataType {
    return {
      memberStatus: null,
      member: null,
      property: null,
      isRouting: false,
      isMounting: false,
      hasTransitionSourceServiceIdUaTypeEmansion: false,
      hasTransitionSourceServiceIdUaTypeUcom: false,
      existsInParameterDecisionTable: false,
      contractedProductIdList: new Array<number>(),
      isGameWith: false,
    };
  },
  async mounted() {
    // eslint-disable-next-line no-useless-catch

    try {
      this.isMounting = true;

      // 新規会員登録が実行されるタイミングは次の通り
      // | No  | query-parameter                                                    | where-portas-member-is-created | ISP                   |
      // | --- | ------------------------------------------------------------------ | ------------------------------ | --------------------- |
      // | 1   | login-forwarding?from=ucom-r&apid=5130000000003&processtype=regist | /platform/entry/input-email    | ucom                  |
      // | 2   | login-forwarding?from=emansion&apid=24031315&processtype=regist    | /platform/entry/input-email    | e-mansion             |
      // | 3   | no-query-parameter or invalid-query-parameter                      | /platform/entry/confirm        | ucom,e-mansion,Five.A |
      //
      // login-forwarding?from=Five.A&apid=AA0146&processtype=regist は
      // /platform/enry/input に遷移して、会員登録なので、 No.3 に含まれる

      this.memberStatus = this.$store.getters['memberStore/memberStatus'];
      this.member = this.$store.getters['memberStore/member'];

      if (!this.member || !this.memberStatus) {
        // データ不整合エラー
        throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.DATA_INCONSISTENT);
      }

      if (this.member.propertyId) {
        this.property = this.$store.getters['propertyStore/property'];
      }

      if (this.memberStatus.transitionSourceService && this.memberStatus.transitionSourceServiceId) {
        if (this.memberStatus.transitionSourceService === TRANSITION_SOURCE_SERVICE.E_MANSION) {
          this.hasTransitionSourceServiceIdUaTypeEmansion = true;
        } else if (this.memberStatus.transitionSourceService === TRANSITION_SOURCE_SERVICE.UCOM) {
          this.hasTransitionSourceServiceIdUaTypeUcom = true;
        }
        //  パラメータ判定テーブルにある物件か確認する
        if (await JudgeParameterDecisionProperty.existsOnPortasDB(this.memberStatus.transitionSourceServiceId)) {
          // パラメータ判定テーブルにある物件の場合
          this.$data.existsInParameterDecisionTable = true;
        }
      }

      const entryInputForm: PlatformEntryInputForm | null = this.$store.getters['platformEntryStore/entryInputForm'];

      if (entryInputForm) {
        this.contractedProductIdList = entryInputForm.contractedProductIdList;
      }

      this.isMounting = false;

      if (this.memberStatus.encryptedPrimaryKeyGw) {
        this.isGameWith = true;
      }

      this.$data.isMounting = false;
    } catch (error) {
      throw error;
    }
  },
  methods: {
    async onNext() {
      // onNext()が複数回実行されないようにする
      if (this.isRouting) {
        return;
      }

      this.isRouting = true;
      try {
        /** 物件が取得できなかった場合（≒ お住まいを登録しなかった場合） */
        if (!this.property) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          await this.$router.push('/platform').catch((error: any) => {
            checkRouterError(error);
          });
          return;
        }
        /** UCOM 自動紐づけの場合 */
        if (this.member?.primaryKeyUcom) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          await this.$router.push('/ucom').catch((error: any) => {
            checkRouterError(error);
          });
          return;
        }
        /** e-mansion 自動紐づけの場合 */
        if (this.member?.primaryKeyMye) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          await this.$router.push('/e-mansion').catch((error: any) => {
            checkRouterError(error);
          });
          return;
        }
        /** ※現在は生じえない（mCloud 自動紐づけの場合） */
        if (this.member?.primaryKeyMcloud) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          await this.$router.push('/mcloud').catch((error: any) => {
            checkRouterError(error);
          });
          return;
        }
        /**お住まいを登録しなかった場合ログイン後トップに遷移する */
        if (this.memberStatus?.status === MEMBER_STATUS.ACCOUNT_REGISTERED_NO_PROPERTY) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          await this.$router.push('/platform').catch((error: any) => {
            checkRouterError(error);
          });
          return;
        } else if (this.memberStatus?.status === MEMBER_STATUS.PROPERTY_LINKED || this.memberStatus?.status === MEMBER_STATUS.ACCOUNT_REGISTERED) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          await this.$router.push('/platform').catch((error: any) => {
            checkRouterError(error);
          });
          return;
          /**会員ステータスが3, 4, 5 以外 */
        } else {
          new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.MEMBER_STATUS);
        }
      } catch (error) {
        throw error;
      }
    },
    async onRegisterPayment() {
      await this.$router.push('/platform/my-page/payment-method/list');
    },
  },
});
</script>
